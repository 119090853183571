// app/actions.ts
'use server';
import prisma from '@/lib/prisma'; // Adjust the path based on your prisma setup
import axios from 'axios';

const PAGE_SIZE = 10;

export async function getLeaderboardDataForFaction(factionId: number, page = 1) {
  const skip = (page - 1) * PAGE_SIZE;
  const take = PAGE_SIZE;

  const totalCount = await prisma.user.count({
    where: {
      factionId,
    },
  });

  const users = await prisma.user.findMany({
    skip,
    take,
    where: {
      factionId,
    },
    orderBy: {
      points: 'desc',
    },
  });

  return {
    users,
    page,
    totalPages: Math.ceil(totalCount / PAGE_SIZE),
    pageSize: PAGE_SIZE,
  };
}

export async function myServerAction() {
  const now = new Date();
  const currentTimeInSeconds = Math.floor(now.getTime() / 1000);

  // Calculate seconds since the last 4-hour reset (e.g., 00:00, 04:00, 08:00, etc.)
  const secondsSinceLastReset = currentTimeInSeconds % (4 * 60 * 60);

  // Calculate remaining seconds until the next 4-hour reset
  const secondsLeft = 8 * 60 * 60 - secondsSinceLastReset;

  return { secondsLeft };
}

/**
 * Fetch user referrals and points using wallet address.
 * @param walletAddress The wallet address of the user.
 * @returns Total referrals and referral points.
 */
export async function getUserReferralsByWallet(walletAddress: string) {
  if (!walletAddress) {
    return {
      totalReferrals: 0,
      totalPoints: 0,
      userId: null,
    };
  }

  // Step 1: Fetch the user and validate existence
  const user = await prisma.user.findUnique({
    where: { walletAddress },
    select: {
      id: true,
      points: true,
    },
  });

  if (!user) {
    return {
      totalReferrals: 0,
      totalPoints: 0,
      userId: null,
    };
  }

  const { id: userId } = user;

  // Step 2: Count referrals and sum referral points in parallel
  const [totalReferrals, totalReferralPoints] = await Promise.all([
    // Count referrals where referrer_id matches the user ID
    prisma.user.count({
      where: { referrer_id: userId },
    }),
    // Aggregate referral points for this user
    prisma.referralPoints.aggregate({
      _sum: {
        points: true,
      },
      where: { referrerId: userId },
    }),
  ]);

  // Step 3: Return formatted result
  return {
    totalReferrals,
    totalPoints: totalReferralPoints._sum.points || 0,
    userId,
  };
}

export async function fetchUserData(walletAddress: string) {
  if (!walletAddress) return { factionId: null, userPoints: 0 };
  // Fetch user data from Prisma
  const user = await prisma.user.findUnique({
    where: { walletAddress },
    // select: {
    //   id: true,
    //   factionId: true,
    //   points: true,
    //   // id: true,
    // },
  });

  if (!user) {
    return {
      factionId: null,
      userPoints: 0,
      // id: null,
    };
  }

  const completedTasks = await prisma.userTask.findMany({
    where: { userId: user.id },
    select: {
      id: true,
      taskId: true,
      completedAt: true,
      lastCompletedAt: true,
    },
  });

  const claimedRoles = await prisma.claimedRole.findMany({
    where: { userId: user.id },
    select: {
      role: true,
    },
  });

  // const referrals = await prisma.user.count({
  //   where: { referrer_id: user.id },
  // });

  // const refPoints = await prisma.referralPoints.aggregate({
  //   where: { referrerId: user.id },
  //   _sum: {
  //     points: true,
  //   },
  // });

  return {
    factionId: user?.factionId ?? null,
    userPoints: user?.points ?? 0,
    completedTasks,
    discordUsername: user?.discordUserName,
    claimedRoles,
    // id: user?.id,
    // referrals: referrals,
    // referralPoints: refPoints._sum.points,
  };
}

export async function getCompleteUserData(identifier: string | number) {
  if (!identifier) {
    throw new Error('User ID or Wallet Address is required.');
  }
  const isNumericString = (s: string) => /^\d+$/.test(s);

  const query =
    typeof identifier === 'number' ||
    (typeof identifier === 'string' && isNumericString(identifier))
      ? { id: Number(identifier) } // Treat as ID if numeric
      : { walletAddress: identifier.toString() }; // Otherwise use wallet address

  const user = await prisma.user.findUnique({
    where: query,
    select: {
      telegramInviteLink: true,
      username: true,
      id: true,
      points: true,
      ClaimedRole: {
        select: {
          role: true,
          createdAt: true,
        },
      },
      faction: {
        select: {
          id: true,
          name: true,
        },
      },
      tasks: {
        select: {
          taskId: true,
          completedAt: true,
          lastCompletedAt: true,
        },
      },
    },
  });

  const completedTaskIds = user?.tasks.map((task) => task.taskId);

  if (!user) {
    return { error: 'User not found. Please complete your profile.' };
  }

  const allocation = getUserPrizeAllocation(user.id);

  // Count the number of users referred by this user (using the user's id as the referrer_id)
  const referralCount = await prisma.user.count({
    where: { referrer_id: user.id },
  });

  // Return all the fetched user data along with the referral count
  return {
    ...user,
    completedTaskIds,
    referralCount,
    allocation,
  };
}

const totalPrizePool = 25000000;

async function getUserPrizeAllocation(userId: number) {
  // Get total points from all users
  const totalPoints = await prisma.user.aggregate({
    _sum: { points: true },
  });

  // Get the user's points
  const user = await prisma.user.findUnique({
    where: { id: userId },
    select: { points: true },
  });

  if (!user || !totalPoints._sum.points) {
    return { userId, allocatedPrize: 0 };
  }

  // Calculate the allocated prize
  const allocatedPrize = (user.points / totalPoints._sum.points) * totalPrizePool;

  return allocatedPrize;
}

export async function getCompletedTasks(walletAddress: string) {
  if (!walletAddress) {
    throw new Error('Wallet address is required');
  }

  // Validate the user
  const existingUser = await prisma.user.findUnique({
    where: { walletAddress },
  });

  if (!existingUser) {
    return { error: 'User not found. Please complete your profile.' };
  }

  // Retrieve completed tasks for the user
  const completedTasks = await prisma.userTask.findMany({
    where: {
      userId: existingUser.id,
    },
    select: {
      taskId: true,
    },
    // cacheStrategy: { ttl: 60 },
  });

  // Extract task IDs
  const completedTaskIds = completedTasks.map((task) => task.taskId);

  // Revalidate the path (optional, based on where your data is used)
  // revalidatePath('/path-to-revalidate'); // Replace with the path you need to revalidate

  return completedTaskIds;
}

const telegramApi = process.env.TELEGRAM_TOKEN;
const telegramChatId = process.env.TELEGRAM_CHAT_ID;

export async function getOrCreateInviteLink(walletAddress: any) {
  try {
    if (!walletAddress) {
      throw new Error('Wallet address is required.');
    }

    // Fetch user and their completed tasks
    const user = await prisma.user.findUnique({
      where: { walletAddress },
      include: {
        tasks: true, // Fetch user's completed tasks
      },
    });

    if (!user) {
      return { error: 'User not found. Please complete your profile.' };
    }

    // Get the total number of tasks
    const totalTasks = await prisma.task.count();

    // Check if the user has completed task with ID 5
    const hasCompletedTask5 = user.tasks.some((task) => task.taskId === 5);

    if (!hasCompletedTask5) {
      return {
        error: 'You must complete the task with ID 4 before generating an invite link.',
      };
    }
    // If the user exists and already has an invite link, return it
    if (user.telegramInviteLink) {
      return {
        success: true,
        inviteLink: user.telegramInviteLink,
        exists: true,
      };
    }

    // Generate a new Telegram invite link
    const apiUrl = `https://api.telegram.org/bot${telegramApi}/createChatInviteLink`;
    const response = await axios.post(apiUrl, {
      chat_id: telegramChatId,
      member_limit: 1,
    });

    if (response.data.ok) {
      const telegramInviteLink = response.data.result.invite_link;

      // Update the user's record with the new invite link
      await prisma.user.update({
        where: { walletAddress },
        data: { telegramInviteLink },
      });

      return {
        success: true,
        inviteLink: telegramInviteLink,
        exists: false,
      };
    }

    throw new Error('Error creating invite link');
  } catch (error: any) {
    console.error('Error:', error);
    return {
      success: false,
      error: error.message || 'An unknown error occurred',
    };
  }
}

export async function checkAndRegisterUser(walletAddress: string) {
  const user = await prisma.user.findUnique({
    where: { walletAddress },
    include: {
      tasks: true, // Fetch user's completed tasks
    },
  });

  if (!user) {
    // Log the error to an external logging service if needed
    return { error: 'User not found. Please complete your profile.' };
  }

  // Get the total number of tasks
  const totalTasks = await prisma.task.count();
  const completedTasks = user.tasks.length;

  // Check if the user has completed all tasks
  const tasksCompleted = completedTasks === totalTasks;

  // if (!tasksCompleted) {
  //   return {
  //     error: `Please complete all ${totalTasks - completedTasks} remaining tasks to register.`,
  //   };
  // }

  // Check if the user is already registered
  if (user.eventRegisteredAt) {
    return {
      isRegistered: true,
      tasksCompleted,
    };
  }

  // Register the user if all tasks are completed
  await prisma.user.update({
    where: { walletAddress },
    data: { eventRegisteredAt: new Date() },
  });

  return {
    success: true,
    isRegistered: true,
    tasksCompleted,
  };
}

export async function checkRegistrationStatus(walletAddress: string) {
  const user = await prisma.user.findUnique({
    where: { walletAddress },
  });

  return {
    isRegistered: !!user?.eventRegisteredAt,
  };
}
