'use client';
import { Button } from '@antcorefinance/ui';
import { Dots } from '@antcorefinance/ui';
import { useWallet } from '@aptos-labs/wallet-adapter-react';
import { AnimatePresence, motion } from 'framer-motion';
import { BadgeCheck, Gem, RotateCcw, Share2, Shield, Star, Users } from 'lucide-react';
import Image from 'next/image';
import { useRouter } from 'next/navigation';
import router from 'next/router';
import React, { useEffect, useState } from 'react';
import CountUp from 'react-countup';

const modalVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { duration: 0.25, ease: 'easeOut' }, // Faster fade in
  },
  exit: {
    opacity: 0,
    transition: { duration: 0.15, ease: 'easeIn' }, // Quicker fade out
  },
};

// const stepVariants = {
//   hidden: { opacity: 0, scale: 0.8 },
//   visible: {
//     opacity: 1,
//     scale: 1,
//     transition: { duration: 0.5, ease: 'backOut' },
//   },
//   exit: {
//     opacity: 0,
//     scale: 1.2,
//     transition: { duration: 0.3, ease: 'easeIn' },
//   },
// };
const RECAP_STEPS = [
  {
    name: 'Antcore Testnet Recap',
    description: '',
    emoji: '🚀',
  },
  {
    name: 'Community Growth',
    description: '',
    emoji: '🌟',
  },
  {
    name: 'Total Impressions',
    description: '674209',
    emoji: '👀',
  },
  {
    name: 'New Community Members',
    description: '18509',
    emoji: '📈',
  },
  {
    name: 'Discord Community',
    description: '17509',
    emoji: '👾',
  },
  {
    name: 'Active Participants',
    description: '18975',
    emoji: '🕹️',
  },
  {
    name: 'Testnet Transactions',
    emoji: '💱', // Money with wings emoji
    description: '160000',
  },
  {
    name: 'Total Points Earned',
    emoji: '⚡',
    description: '96455763',
  },
  {
    name: 'Liquidity Providers',
    emoji: '💧',
    description: '28826',
  },
  {
    name: 'Total Value Engaged',
    emoji: '💲',
    description: '250370000',
  },
  {
    name: 'Mainnet Launch Incoming...',
    emoji: '🚨',
    description: '',
  },
  {
    name: 'Mainnet Launch Incoming...',
    emoji: '🚨',
    description: '',
  },
];

const stepVariants = {
  hidden: { opacity: 0, scale: 0.8 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: { duration: 0.5, ease: 'backOut' },
  },
  exit: {
    opacity: 0,
    scale: 1.2,
    transition: { duration: 0.3, ease: 'easeIn' },
  },
};

const contentVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: { duration: 0.4, ease: 'easeOut' },
  },
};

const itemVariants = {
  hidden: { opacity: 0, x: -20 },
  visible: (i: number) => ({
    opacity: 1,
    x: 0,
    transition: { delay: i * 0.1, duration: 0.3, ease: 'easeOut' },
  }),
};

// const RECAP_STEPS = [
//   {
//     name: 'GM ANTCORE',
//     description: '',
//   },
//   {
//     name: 'WRAPPING UP TESTNET',
//     description: '',
//   },
//   {
//     name: '🥁',
//     description: '',
//   },
//   {
//     name: 'Impressions',
//     description: '674209',
//     emoji: '👀',
//   },
//   {
//     name: 'Followers Gained',
//     description: '15000',
//     emoji: '📈',
//   },
//   {
//     name: 'Discord Family',
//     description: '17509',
//     emoji: '👪',
//   },
//   {
//     emoji: '🕹️',
//     name: 'Registered Users',
//     description: '18975',
//   },
//   {
//     name: 'Total eANTC Points',
//     emoji: '⚡',
//     description: '96455763',
//   },
//   {
//     name: 'Market Makers',
//     emoji: '🟢',
//     description: '28826',
//   },
//   {
//     name: 'Total Net Worth',
//     emoji: '💲',
//     description: '250370000',
//   },
//   {
//     name: (
//       <>
//         <div className="flex flex-row"> And we are just getting started </div>
//       </>
//     ),
//     emoji: '',
//     description: '',
//   },
//   {
//     name: 'Impressions',
//     description: '674209',
//   },
// ];

export default function RecapModal({ isOpenManually, userdata }: any) {
  const { account } = useWallet();
  const [isOpen, setIsOpen] = useState(isOpenManually || false);
  const [currentStep, setCurrentStep] = useState(0);
  // const [userData, setUserData] = useState<{
  //   points: number;
  //   completedTasks: number;
  //   faction?: { name: string; rank: number; totalPoints: number };
  //   recentTasks: Array<{ title: string; points: number }>;
  // } | null>(null);
  const [loading, setLoading] = useState(false);
  const fonts = [,];

  // const [fontIndex, setFontIndex] = useState(0);
  const router = useRouter();

  const shareRecap = () => {
    const location = window.location.href;
    const recapUrl = userdata?.id;
    const tweetText = encodeURIComponent('Check out my @antcorefinance testnet recap!🌟');
    const twitterShareUrl = `https://twitter.com/intent/tweet?text=${tweetText}&url=${location}recap?id=${recapUrl}`;
    window.open(twitterShareUrl, '_blank');
  };

  // useEffect(() => {
  //   const fontTimer = setInterval(() => {
  //     setFontIndex((prevIndex) => (prevIndex + 1) % fonts.length);
  //   }, 1000); // Change font every 1.5 seconds

  //   return () => clearInterval(fontTimer);
  // }, []);

  // const fetchUserRecap = async () => {
  //   // if (!account?.address) return;
  //   console.log('raoo', userdata);
  //   setLoading(true);
  //   try {
  //     const mockData = {
  //       points: userdata.points,
  //       completedTasks: userdata.completedTaskIds,
  //       faction: { name: 'The Forge', rank: 3, totalPoints: 245000 },
  //       recentTasks: [
  //         //   { title: userdata.claimedRole.role ? 'Follow on Twitter' : '', points: 1000 },
  //         { title: 'Daily Check-in', points: 300 },
  //         { title: 'APT Swap', points: 2500 },
  //       ],
  //     };
  //     setUserData(mockData);
  //   } catch (error) {
  //     console.error('Failed to load recap data:', error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const taskRange = Array.from({ length: 10 }, (_, i) => 300 + i);

  const hasCompletedOnboarding =
    userdata?.completedTaskIds.includes(1) && userdata?.completedTaskIds.includes(11);

  const hasProvidedLiqudity =
    userdata?.completedTaskIds.includes(1) && userdata?.completedTaskIds.includes(11);

  const hasCompletedTestnetTasks = userdata?.userdata?.completedTaskIds?.includes(2);

  const mappedTasks = taskRange.map((taskId) => ({
    taskId,
    completed: userdata?.completedTaskIds?.includes(taskId),
  }));

  // In the component's useEffect for step timing:
  useEffect(() => {
    if (!isOpen) return;

    const currentStepData = RECAP_STEPS[currentStep];
    if (!currentStepData) return;

    // Faster transitions based on content type

    const delay = currentStepData.description ? 3000 : 2000;

    const timer = setTimeout(() => {
      if (currentStep < RECAP_STEPS.length - 1) {
        setCurrentStep((prev) => prev + 1);
      }
    }, delay);

    return () => clearTimeout(timer);
  }, [isOpen, currentStep]);

  const CAMPAIGN_END_DATE = new Date(1740554004 * 1000);

  CAMPAIGN_END_DATE.setDate(CAMPAIGN_END_DATE.getDate() + 2); // 2 days from now

  return (
    <>
      {/* <button
        onClick={() => setIsOpen(true)}
        className="fixed bottom-6 right-6 z-50 flex items-center gap-2 rounded-full bg-[#077d1e] px-4 py-2 font-mono text-sm font-bold text-white shadow-lg transition hover:bg-[#0a9e2a]"
      >
        <RotateCcw size={18} />
        Testnet Recap
      </button> */}

      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 z-50 bg-black/90 backdrop-blur-sm"
          >
            <motion.div
              variants={modalVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
              className="relative flex h-full max-h-screen w-full items-center justify-center overflow-x-hidden"
            >
              <button
                onClick={() => setIsOpen(false)}
                className="absolute right-6 top-6 z-50 text-2xl text-gray-400 hover:text-white"
              >
                ✕
              </button>

              {currentStep < RECAP_STEPS.length - 1 ? (
                <>
                  <AnimatePresence mode="wait">
                    <motion.div
                      key={currentStep}
                      variants={stepVariants}
                      initial="hidden"
                      animate="visible"
                      exit="exit"
                      className="flex h-screen w-full flex-col items-center justify-center"
                    >
                      {RECAP_STEPS[currentStep].emoji && (
                        <>
                          <h1
                            className={`from-green-300 mb-2 bg-gradient-to-r via-blue-500 to-purple-600 bg-clip-text text-7xl font-bold text-transparent text-white md:text-7xl`}
                          >
                            {RECAP_STEPS[currentStep].emoji}
                          </h1>
                        </>
                      )}
                      <h1 className="from-green-300 bg-gradient-to-r via-blue-500 to-purple-600 bg-clip-text text-5xl font-bold text-transparent text-white sm:text-6xl">
                        {RECAP_STEPS[currentStep].description && (
                          <>
                            {RECAP_STEPS[currentStep].name === 'Total Value Engaged' ? '$' : ''}
                            <CountUp
                              start={0}
                              end={Number(RECAP_STEPS[currentStep].description)}
                              decimals={0}
                              duration={5}
                              separator=","
                            />
                            +{RECAP_STEPS[currentStep].name === 'Total Value Engaged' ? ' USD' : ''}
                          </>
                        )}
                      </h1>
                      <h1
                        className={`from-green-300 bg-gradient-to-r via-blue-500 to-purple-600 bg-clip-text text-3xl font-bold text-transparent text-white md:text-6xl ${
                          RECAP_STEPS[currentStep].name === 'GM ANTCORE' ? '' : ''
                        }`}
                      >
                        {RECAP_STEPS[currentStep].name}
                      </h1>
                    </motion.div>
                  </AnimatePresence>
                </>
              ) : (
                <div className="card-border card-shadow m-auto flex max-w-5xl flex-col items-center rounded-xl bg-cardBlack p-8">
                  {/* <motion.div
                    variants={contentVariants}
                    className="grid grid-cols-1 gap-6 mt-8 md:grid-cols-3"
                  >
                    <div className="p-6 space-y-2 text-center rounded-xl bg-gray-900/50">
                      <div className="text-4xl font-bold text-white">673,580</div>
                      <div className="text-gray-400">Impressions</div>
                    </div>
                    <div className="p-6 space-y-2 text-center rounded-xl bg-gray-900/50">
                      <div className="text-4xl font-bold text-white">14.8k</div>
                      <div className="text-gray-400">Followers Gained</div>
                    </div>
                    <div className="p-6 space-y-2 text-center rounded-xl bg-gray-900/50">
                      <div className="text-4xl font-bold text-white">18964</div>
                      <div className="text-gray-400">Addresses Registered</div>
                    </div>
                  </motion.div> */}

                  <motion.h2
                    variants={contentVariants}
                    initial="hidden"
                    animate="visible"
                    className="mb-12 flex items-center gap-3 text-3xl font-bold"
                  >
                    {/* <BadgeCheck className="text-[#077d1e]" /> */}
                    Your Contribution
                  </motion.h2>

                  {loading ? (
                    <div className="flex h-64 items-center justify-center">
                      <div className="loader" />
                    </div>
                  ) : userdata ? (
                    <div className="w-full max-w-4xl space-y-8">
                      <motion.div
                        variants={contentVariants}
                        className="grid grid-cols-1 gap-6 md:grid-cols-2"
                      >
                        <div className="space-y-2 rounded-xl bg-gray-900/50 p-6 text-center">
                          <div className="text-4xl font-bold text-white">⚡ {userdata.points}</div>
                          <div className="text-gray-400">Your eANTC Points</div>
                        </div>
                        <div className="space-y-2 rounded-xl bg-gray-900/50 p-6 text-center">
                          <div className="flex flex-row items-center justify-center gap-2">
                            <Image
                              alt=""
                              height={30}
                              width={30}
                              src="https://tokens-antcore.vercel.app/images/aptos/0x68f95fd204e6bd1f3c44dcf610beb56989a08ebd155fe2c12bae66123a6dae02::antcore::AntcoreCoin.svg"
                            ></Image>
                            <div className="text-4xl font-bold text-white">
                              {Math.min(Number(userdata.allocation?.value), 150000).toFixed(2) ||
                                '0.00'}
                            </div>
                          </div>
                          <div className="text-gray-400">Current ANTC Allocation</div>
                        </div>
                        {/* <div className="p-6 space-y-2 text-center rounded-xl bg-gray-900/50">
                          <div className="text-4xl font-bold text-purple-400">
                            #{userData.faction?.rank || '-'}
                          </div>
                          <div className="text-gray-400">Faction Rank</div>
                        </div> */}
                      </motion.div>

                      {/* {userData.faction && (
                        <motion.div
                          variants={contentVariants}
                          className="p-6 rounded-xl bg-gray-900/50"
                        >
                          <h3 className="flex items-center gap-2 mb-4 text-xl font-semibold">
                            <Users className="text-blue-400" />
                            Faction Status
                          </h3>
                          <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
                            <div>
                              <div className="text-gray-400">Name</div>
                              <div className="text-lg">{userData.faction.name}</div>
                            </div>
                            <div>
                              <div className="text-gray-400">Your Contribution</div>
                              <div className="text-lg text-emerald-400">{userData.points} pts</div>
                            </div>
                            <div>
                              <div className="text-gray-400">Total Points</div>
                              <div className="text-lg">{userData.faction.totalPoints} pts</div>
                            </div>
                          </div>
                        </motion.div>
                      )} */}

                      <motion.div
                        variants={contentVariants}
                        className="rounded-xl bg-gray-900/50 p-6"
                      >
                        <h3 className="mb-4 flex items-center gap-2 text-xl font-semibold">
                          <Shield className="text-yellow-400" />
                          Recent Achievements
                        </h3>
                        <div className="space-y-3">
                          {userdata?.ClaimedRole[0]?.role === 'AntScout' ? (
                            <>
                              <motion.div
                                variants={itemVariants}
                                className="flex items-center justify-between rounded-lg bg-gray-800/50 p-4"
                              >
                                <span>Claimed Antcore Scout Discord Role</span>
                                <span className="text-emerald-400">+1000</span>
                              </motion.div>{' '}
                            </>
                          ) : (
                            <></>
                          )}

                          {/* {hasCompletedTestnetTasks ? (
                            <>
                              <motion.div
                                variants={itemVariants}
                                className="flex items-center justify-between p-4 rounded-lg bg-gray-800/50"
                              >
                                <span>Completed Tesnet Tasks </span>
                                <span className="text-emerald-400">+2000</span>
                              </motion.div>{' '}
                            </>
                          ) : (
                            <></>
                          )} */}

                          {hasProvidedLiqudity ? (
                            <>
                              <motion.div
                                variants={itemVariants}
                                className="flex items-center justify-between rounded-lg bg-gray-800/50 p-4"
                              >
                                <span>Mastered Testnet Swaps and Rewards</span>
                                <span className="text-emerald-400">+2000</span>
                              </motion.div>{' '}
                            </>
                          ) : (
                            <></>
                          )}

                          {hasCompletedOnboarding ? (
                            <>
                              <motion.div
                                variants={itemVariants}
                                className="flex items-center justify-between rounded-lg bg-gray-800/50 p-4"
                              >
                                <span>Completed Initial Onboarding Tasks</span>
                                <span className="text-emerald-400">+2000</span>
                              </motion.div>{' '}
                            </>
                          ) : (
                            <></>
                          )}

                          {mappedTasks ? (
                            <>
                              <motion.div
                                variants={itemVariants}
                                className="flex items-center justify-between rounded-lg bg-gray-800/50 p-4"
                              >
                                <span>
                                  Amplified Antcore&apos;s Message{' '}
                                  {mappedTasks.filter((task) => task.completed).length} Times
                                </span>
                                <span className="text-emerald-400">
                                  +{mappedTasks.filter((task) => task.completed).length * 500}
                                </span>
                              </motion.div>{' '}
                            </>
                          ) : (
                            <></>
                          )}

                          {userdata.referralCount > 0 ? (
                            <>
                              <motion.div
                                variants={itemVariants}
                                className="flex items-center justify-between rounded-lg bg-gray-800/50 p-4"
                              >
                                <span>Referred</span>
                                <span className="text-emerald-400">
                                  {userdata.referralCount} Users{' '}
                                </span>
                              </motion.div>{' '}
                            </>
                          ) : (
                            <></>
                          )}

                          {/* {mappedTasks.map((task, i) => (
                            <motion.div
                              key={i}
                              custom={i}
                              variants={itemVariants}
                              className="flex items-center justify-between p-4 rounded-lg bg-gray-800/50"
                            >
                              <span>{task.taskId}</span>
                              <span className="text-emerald-400">+{task.completed}</span>
                            </motion.div>
                          ))} */}
                        </div>
                      </motion.div>

                      <motion.div
                        variants={contentVariants}
                        className="mb-8 w-full rounded-lg border border-yellow-500/30 bg-yellow-900/20 p-4 text-center"
                      >
                        <div className="flex items-center justify-center gap-3 text-yellow-300">
                          <span className="text-2xl">⏳</span>
                          <div>
                            <h3 className="font-bold">Testnet Campaign Ends Soon!</h3>
                            <p className="text-sm">
                              Complete Mainnet tasks for{' '}
                              <span className="text-green-400 font-bold">decisive points</span> and{' '}
                              <span className="font-bold text-purple-400">bonus allocations</span>{' '}
                            </p>
                          </div>
                          <span className="text-2xl">⚡</span>
                        </div>
                      </motion.div>

                      <motion.div
                        variants={contentVariants}
                        className="mb-8 w-full rounded-lg border border-blue-500/30 bg-blue-900/20 p-4 text-center"
                      >
                        <div className="flex items-center justify-center gap-3 text-blue-300">
                          <span className="text-2xl">🚀</span>
                          <div>
                            <h3 className="font-bold">Mainnet Era Begins</h3>
                            <p className="text-sm">
                              Testnet leaderboard will reset and evolve into{' '}
                              <span className="text-green-400 font-bold">Mainnet Season</span> with
                            </p>
                            <div className="mt-2 flex justify-center gap-4 text-xs">
                              <span className="flex items-center gap-1">
                                <Star className="h-3 w-3" /> New Features
                              </span>
                              <span className="flex items-center gap-1">
                                <Shield className="h-3 w-3" /> Enhanced Rewards
                              </span>
                              <span className="flex items-center gap-1">
                                <Users className="h-3 w-3" /> Ongoing Campaigns
                              </span>
                            </div>
                          </div>
                          <span className="text-2xl">🌟</span>
                        </div>
                      </motion.div>

                      {account?.address && (
                        <motion.div className="mt-6 flex justify-center">
                          <Button
                            onClick={shareRecap}
                            className="flex items-center gap-2 rounded-lg bg-blue-600 px-4 py-2 font-bold text-white hover:bg-blue-700"
                          >
                            <Share2 size={18} /> Share Recap & Earn Bonus Points and Referrals
                          </Button>
                        </motion.div>
                      )}

                      <div className="flex items-center justify-center">
                        <Button
                          variant="outlineclose"
                          onClick={() => {
                            setIsOpen(false);
                            router.push('/');
                          }}
                          className="rounded-md px-4 py-2 text-white"
                        >
                          Close
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <motion.div variants={contentVariants} className="text-gray-400">
                      No campaign data available
                    </motion.div>
                  )}
                </div>
              )}
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}
